<template>
  <Layer
    :title="getDropBoxOption.title"
    :isCloseBtn="getDropBoxOption.isCloseBtn"
    @onClickClose="onClickDropClose()">
    <ul
      v-if="getDropBoxDataList.length > 0"
      class="list_option"
      ref="scrollBody">
      <li
        v-for="item in getDropBoxDataList"
        :key="item.id"
        :class="{ active: isActive(item.id), disabled : item.isDisabled }">
        <button
          class="btn_option"
          :disabled="item.isDisabled"
          @click="onClickOption(item)">
          <div v-if="getDropBoxOption.thumnailKey && item[getDropBoxOption.thumnailKey]" class="img_option" :style="{ 'background-image' : `url(${item[getDropBoxOption.thumnailKey]})`}"></div>
          <p v-if="item.info_head" class="desc_info" v-html="item.info_head"></p>
          <span class="txt_option">{{ item.text }}</span>
          <p v-if="item.info" class="desc_info" v-html="item.info"></p>
          <IconSvg v-if="isActive(item.id)" iconName="check" size="24" iconColor="#8F69DD"/>
        </button>
      </li>
    </ul>
    <div
      v-else
      class="area_empty"
      ref="scrollBody">
      <p class="desc_empty">데이터가 없습니다.</p>
    </div>
  </Layer>
</template>

<script>
import Layer from '@/components/layout/popup/Layer';
import IconSvg from '@/components/common/icon/IconSvg';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DropBoxSelect',
  components: {
    Layer,
    IconSvg
  },
  data(){
    return{
      isShowDropBox: false,
    }
  },
  mounted() {
    this.dropBoxActive();
    
    this.setScrollReset();
  },
  beforeUnmount() {
    this.isShowDropBox = false;
    if (this.dropBoxActive) clearTimeout(this.dropBoxActive);
  },
  computed: {
    ...mapGetters('commonViewport', [ 'getWindowHeight' ]),
    ...mapGetters('commonDropBox', [ 'getDropBoxUid', 'getIsSelectDropBox', 'getDropBoxDataList', 'getDropBoxOption', 'getDropBoxValue']),
    dropBoxHeight(){
      if(!this.getDropBoxOption || ( !this.getDropBoxOption.title || !this.getDropBoxOption.isCloseBtn )) return this.getWindowHeight -100 - 32
      if(this.getDropBoxOption){
        return this.getWindowHeight -100 - 68 - 32
      }
    },
    cssProps(){
      var obj = {};
      obj = {
        '--height': `${(this.dropBoxHeight)}px`,
      }
      return obj;
    },
  },
  watch:{
    getDropBoxUid(){
      if(this.getDropBoxUid === null) return;
      this.setScrollReset();
    },
    getIsSelectDropBox(){
      if(this.getIsSelectDropBox){
        this.setScrollReset();
      }
    },
  },
  methods:{
    ...mapActions('commonDropBox', [ 'fetchDropBoxSelect', 'fetchDropBoxEnd' ]),
    dropBoxActive(){
      const _this = this;
      this.timeoutID0 = setTimeout(function(){
        _this.isShowDropBox = true;
        this.timeoutID0 = null;
      }, 50);
    },
    setScrollReset(){
      if(!this.$refs.scrollBody) return;
      if(this.getDropBoxValue && !this.getDropBoxOption.canMultiple){
        const selectedIndex = this.getDropBoxDataList.findIndex(item => item.id === this.getDropBoxValue);
        this.$refs.scrollBody.scrollTop = ( 56*selectedIndex ) - (this.dropBoxHeight/2) + 22;
      }else{
        this.$refs.scrollBody.scrollTop = 0;
      }
    },
    isActive(id){
      if(!this.getDropBoxValue) return false;
      if(!this.getDropBoxOption.canMultiple){
        return id === this.getDropBoxValue;
      }else{
        const selectedId = this.getDropBoxValue.indexOf(id) > -1;
        return selectedId;
      }
    },
    onClickOption(option){
      this.fetchDropBoxSelect(option)
      document.body.style.overflow = "";
    },
    onClickDropClose(){
      this.fetchDropBoxEnd();
    }
  }
}
</script>

<style scoped>
/* .box_drop{position:fixed;top:0;bottom:0;left:0;right:0;z-index:300;width:100%;backdrop-filter:blur(1px);height:100%;max-height:inherit}
.box_drop .inner_box_drop{position:absolute;bottom:0;left:0;right:0;width:100%;max-height:0;overflow:hidden;border:0 none;border-radius:8px 8px 0 0;box-sizing:border-box;background-color:#fff}

.head_drop{padding:24px 60px 16px 20px}
.head_drop .tit_drop{display:block;font-weight:700;font-size:20px;line-height:28px;color:#111}
.head_drop .btn_close{position:absolute;top:26px;right:20px}

.list_option{overflow:hidden;overflow-y:auto;max-height:var(--height);padding-bottom:32px;}

.btn_option{position:relative;display:block;overflow:hidden;width:100%;padding:16px 20px;font-weight:700;font-size:16px;line-height:24px;text-align:left;letter-spacing:-.02em;white-space:nowrap}
.btn_option:disabled{cursor:default;color:#999999}
.btn_option .icon_check{position:absolute;top:50%;right:16px;margin-top:-12px}

.btn_option .txt_option{display:block;color:#666666}

.area_empty{padding:28px 0}
.area_empty .desc_empty{color:#AAAAAA;text-align:center}

.box_drop.active{overflow:visible;background-color:rgba(0, 0, 0, 0.6);opacity:1;transition:background-color 0.2s, opacity 0.2s;}
.box_drop.active .inner_box_drop{max-height:1000px;transition:max-height 2s ease-out} */
</style>