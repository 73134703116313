const routerPath = {
  // 카드 이용안내
  CARD_INFOMATION: '/card-infomation',
  CARD_INFOMATION_WEB: '/card-infomation-web',
  // 퍼핀카드 신청
  CARD_REGISTER: '/card-register',
  // 분실신고 / 재발급
  CARD_MISSING: '/card-missing',

  // 약관
  TERM_INDEX : '/term/index',
  // -- 동의서 --
  // 00. 본인확인 서비스 약관
  // 통신사 본인확인 서비스 이용약관
  TERM_IDENTITY_VERIFY_TERMS: '/term/identity-verify-terms',
  // KCB 본인확인 서비스 이용약관
  TERM_IDENTITY_VERIFY_KCB_TERMS: '/term/identity-verify-kcb-terms',
  // 개인정보 수집, 이용 및 위탁 동의
  TERM_IDENTITY_VERIFY_PRIVACY_AGREEMENT: '/term/identity-verify-privacy-agreement',
  // 고유식별정보 처리 동의
  TERM_IDENTITY_VERIFY_INDENTIFICATION_AGREEMENT: '/term/identity-verify-identification-agreement',
  // 알뜰폰 개인정보 제공 동의
  TERM_IDENTITY_VERIFY_3RD_PARTIES: '/term/identity-verify-3rd-parties',

  // 01. 법정대리인 동의 
  // 서비스가입
  TERM_GUARDIAN_AGREEMENT_FOR_SIGNUP: '/term/guardian-agreement-for-signup',
  // 서비스탈퇴
  TERM_GUARDIAN_AGREEMENT_FOR_ACCOUNT_DELETION: '/term/guardian-agreement-for-account-deletion',
  // 카드발급
  TERM_GUARDIAN_AGREEMENT_FOR_ISSUE_CARD: '/term/guardian-agreement-for-issue-card',

  // 02. 광고 마케팅 수신 동의
  TERM_CONSENT_FOR_MARKETING: '/term/consent-for-marketing',
  // 03. 소득공제 신청 동의
  TERM_CONSENT_TAX_SELTTLEMENT: '/term/consent-for-tax-settlement',

  // -- 개인정보 관련 --
  // 11. 개인정보 처리방침
  // 개인정보 처리방침
  TERM_PRIVACY_POLICY_230327: '/term/privacy-policy-230327',
  TERM_PRIVACY_POLICY_240227: '/term/privacy-policy-240227',
  TERM_PRIVACY_POLICY: '/term/privacy-policy',
  // 개인정보 수집 이용 동의
  TERM_PRIVACY_AGREEMENT: '/term/privacy-agreement',
  // 개인정보 제3자 제공 동의
  TERM_PRIVACY_3RD_PARTIES: '/term/privacy-3rd-parties',
  // 개인정보 수집 이용 동의_급식정보
  TERM_PRIVACY_AGREEMENT_SCHOOL_MEAL: '/term/privacy-agreement-school-meal',
  // 개인정보 수집 이용 동의_유료서비스
  TERM_PRIVACY_AGREEMENT_BILL: '/term/privacy-agreement-bill',
  
  // -- 금감원 신고약관 --
  // 12. 전자금융거래 이용약관
  TERM_ELECTRONIC_FINANCIAL_TERMS: '/term/electronic-financial-terms',
  // 13. 퍼핀머니 이용약관
  TERM_FIRFIN_MONEY_TERMS: '/term/firfin-money-terms',
  // 14. 퍼핀페이 서비스 이용약관
  TERM_FIRFIN_PAY_TERMS: '/term/firfin-pay-terms',
  // 21, 퍼핀카드 이용약관
  TERM_FIRFIN_CARD_TERMS: '/term/firfin-card-terms',
  // 22. 레일플러스 이용약관
  TERM_RAILPLUS_TERMS: '/term/railplus-terms',
  // 32. 퍼핀 유료서비스 이용약관
  TERM_BILL: '/term/bill',
  // 32. 퍼핀 유료서비스 이용약관(아임웹 판매페이지용)
  TERM_BILL_IMWEB: '/term/bill-imweb',

  // 고객센터
  HELP:'/help',
  // 주소검색
  ADDRESS: '/address-search',
  // 소득공제 신청 안내
  DEDUCTIONS_AND_EXEMPTIONS_INFO : '/deductions-and-exemptions-info',
  EMPTY: '/empty',
};

export default routerPath;