
export function addComma( value, isDotFormat ) {
  if( value === 0 ) return '0';
  
  if( !value )  return value;

  var strMoney = String( value );
  var isDot = false;
  if( strMoney.indexOf( '.' ) > -1 ){
    var decimal = String( value ).split('.')[1];
    isDot = isDotFormat || Boolean(decimal);
    strMoney = String( value ).split('.')[0];
  }

  if( strMoney.indexOf( ',' ) > -1 ) return strMoney;

  let ret = '';
  let nFromEnd;
  let char;

  for(var i=0; i<strMoney.length; ++i) {
    char = strMoney[ i ];

    nFromEnd = strMoney.length - 1 - i;

    ret += char;
    if( nFromEnd !== 0 && nFromEnd % 3 === 0 && char !== '-' ) {
      ret += ','
    }
  }

  if( isDotFormat ? isDot : decimal ){
    return ret +'.'+ decimal;
  }else{
    return ret
  }
}