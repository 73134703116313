import Vue from "vue";
import { addComma } from '@/utils/stringUtils'
import { convertIdToText } from "@/utils/selectDataUtils";

// ex - 5000000 -> 5,000,000
Vue.filter("convertNumberToComma", (value, isDotFormat, emptyValueText) => {
  if(!value) return emptyValueText || '-';
  return addComma(value, isDotFormat);
})

// selectDataList Value의 text값 바인딩용
Vue.filter("convertIdToText", (value, dataListName, cutLength) => {
  return convertIdToText(value, dataListName, cutLength);
})
