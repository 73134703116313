import Vue from 'vue'
import App from './App.vue'
import router from './router/';
import routerPath from './router/routerPath.js';
import poData from './constants/poData.js';
import store from './store/index';
import apiPath from './services/apiPath';
import * as Sentry from "@sentry/vue";
// import { BrowserTracing } from "@sentry/tracing";
import { Integrations } from "@sentry/tracing";
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/css/swiper.css'
import '@/filters/index';

Vue.config.productionTip = false;

// swiper
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
import {Swiper as SwiperClass, Pagination, Navigation, EffectFade} from 'vue-awesome-swiper'
SwiperClass.use([Pagination, Navigation, EffectFade]);

if(process.env.NODE_ENV == 'development'){
  // Sentry
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing(), //automatic tracing
      // new DedupeIntegration(), //발생하는 많은 에러들의 중복을 제거
      // new CaptureConsoleIntegration({ //console api(error,warn,info 등) 를 캡처링하여 sentry로 보냄.
      //     levels: ["error"]
      // })
    ],
    environment: process.env.NODE_ENV,
    // enabled: process.env.NODE_ENV !== 'development', // development 환경 막기
    ignoreErrors: [

    ] // 해당 event를 캡처링하지 않도록 설정
    // beforeSend(event) { //sentry에 event를 보내기 전에 호출하는 callback, 
    //   // slack 연동
    // },
  });
  Sentry.configureScope(function(scope) {
    scope.setLevel("error");
  });
}

// prototype
Vue.prototype.$routerPath = routerPath;
Vue.prototype.$apiPath = apiPath;
Vue.prototype.$poData = poData;


// 기기구분
const userAgent = navigator.userAgent.toLowerCase() || '';
let varUA;
if ( userAgent.indexOf('android') > -1) {
  varUA = 'ANDROID';
}else if ( userAgent.indexOf("iphone") > -1||userAgent.indexOf("ipad") > -1||userAgent.indexOf("ipod") > -1||userAgent.indexOf("mac") > -1 ) {
  varUA = 'IOS';
}else{
  varUA = 'ETC';
}
Vue.prototype.$varUA = varUA;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
