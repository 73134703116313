const apiPath = {
  // 카드 신청
  CARD_REGISTER_FEE:"/api/card/v1/card-issue/fee", // 카드 발급 비용
  CARD_LOSS_INFO_PARENTS:"/api/card/v1/owner/%s/loss", // 부모) 카드 분실 및 재발급 화면 [ ownerFirfinUserUid ]
  CARD_LOSS_INFO_CHILD:"/api/card/v1/loss", // 자녀) 카드 분실 및 재발급 화면
};
export default apiPath;
  // extension
  // Usage : apiPath.HO_NAME.format(arg1, arg2 ...)
  String.prototype.format = function() {
  return [...arguments].reduce((pattern,value) => pattern.replace(/%s/,value), this);
};