<template>
  <div id="app">
    <router-view></router-view>
    <ToastPopup
      v-if="getIsToastPopup"
      :toastType="getIsToastType"
      :toastMsg="getIsToastMsg"
      @onComplete="onCompleteToast" />
    <AlertPopup
      v-if="getIsAlertPopup"
      :alertMsg="getIsAlertMsg"
      :closeBtnText="getIsCloseBtnText"
      :compeleteBtnText="getIsCompeleteBtnText"
      @onClickClose="onCloseAlert"
      @onClickComplete="onCompleteAlert" />
    <DropBoxSelect v-if="getIsSelectDropBox"/>
  </div>
</template>

<script>
import ToastPopup from '@/components/layout/popup/ToastPopup';
import AlertPopup from '@/components/layout/popup/AlertPopup';
import DropBoxSelect from '@/components/layout/popup/DropBoxSelect';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',
  components:{
    ToastPopup,
    AlertPopup,
    DropBoxSelect
  },
  mounted(){
    window.backKeyEvent = (count) => {
      if(this.getIsAlertPopup){
        if(this.getIsCloseBtnText){
          this.onCloseAlert();
        }else{
          this.onCompleteAlert();
        }
      }else if(this.getIsSelectDropBox){
        this.fetchDropBoxEnd();
      }else{
        this.fetchBackKeyEvent(true);
      }
    }
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeUnmount(){
    window.removeEventListener('resize', this.onResize);
  },
  computed:{
    ...mapGetters('commonToast', [ 'getIsToastPopup','getIsToastMsg','getIsToastType' ]),
    ...mapGetters('commonAlert', [ 'getIsAlertPopup','getIsAlertMsg','getIsCloseBtnText','getIsCompeleteBtnText', 'getConfirmCallBack' ]),
    ...mapGetters('commonDropBox', [ 'getIsSelectDropBox','getIsDateDropBox', 'getIsTimeDropBox']),
  },
  methods:{
    ...mapActions('commonToast', [ 'fetchToastEnd' ]),
    ...mapActions('commonAlert', [ 'fetchAlertEnd' ]),
    ...mapActions('commonViewport', [ 'fetchViewPort' ]),
    ...mapActions('commonDropBox', [ 'fetchDropBoxEnd' ]),
    ...mapActions('commonInterface', [ 'fetchBackKeyEvent' ]),
    onResize(){
      const width = window.innerWidth;
      const height = window.innerHeight;
      this.fetchViewPort({ width: width, height: height });
    },
    onCompleteToast() {
      this.fetchToastEnd();
    },
    onCompleteAlert(){
      this.fetchAlertEnd(true);
      if (this.getConfirmCallBack) {
        this.getConfirmCallBack();
      }
    },
    onCloseAlert(){
      this.fetchAlertEnd(false);
    },
  }
}
</script>
